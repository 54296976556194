<template>
    <b-card no-body header-bg-variant="bluenavy" header="Registro Sport e Salute" header-text-variant="white" header-tag="h2" border-variant="bluenavy">
        <b-card-text>
            <div v-if="!alertdismissed" @click="alertdismissed = true" class="text-right bg-secondary"><button class="btn btn-primary font-weight-bolder"><i class="fas fa-minus text-white"></i> Nascondi Testo</button></div>
            <div v-if="alertdismissed" @click="alertdismissed = false" class="text-right bg-secondary"><button class="btn btn-primary font-weight-bolder"><i class="fas fa-plus text-white"></i> Mostra Testo</button></div>

            <b-alert :show="!alertdismissed" variant="secondary">
                <div style="font-size: 0.9rem !important;">
                    <h6 class="alert-heading text-center">Gestione attività Sportiva - Formativa - Didattica</h6>
                    <p>
                        In questa sezione è possibile inserire da parte della ASD/SSD e del Comitato ACSI di riferimento le attività Sportive e/o Formative e/o Didattiche.<br>
                        <br>
                        Per la permanenza nel nuovo registro è fondamentale inserire una attività sportiva o formativa o didattica.<br>
                        <br>
                        Le attività del 2022 vanno inserite <strong>entro il 31 gennaio 2023</strong>.
                    </p>

                    <h6 class="alert-heading" style="font-size: 1.1rem;">PROCEDURA</h6>

                    <h6 class="alert-heading" style="font-size: 1.0rem;">INSERIMENTO</h6>

                    <ol>
                        <li>Selezionare tipologia di attività da compilare</li>
                        <li>Cliccare su nuova attività</li>
                        <li>Compilare correttamente i dati dell'attività </li>
                        <li>Cliccare sul simbolo <strong>Gestione Partecipante</strong> <span class="btn btn-icon btn-circle btn-sm btn-info mr-3"><i class="fas fa-sign-in-alt"></i></span></li>
                    </ol>

                    <p>
                        <strong>Per le attività sportive e didattiche</strong> è richiesto l'inserimento del numero intero dei partecipanti.<br>
                        <br>
                        <strong>Solamente per attività formative</strong> è richiesto anche l'inserimento dei dati del partecipante: Nome, Cognome, Codice Fiscale partecipante.
                    </p>

                    <h6 class="alert-heading" style="font-size: 1.0rem;">CONFERMA</h6>

                    <ol>
                        <li>L'evento inserito deve essere <strong>confermato</strong> da parte della stessa ASD/SSD/ASD-APS o del Comitato.</li>
                    </ol>

                    <p>
                        Se l'evento è in stato <em><strong>&quot;confermato&quot;</strong></em> può ancora essere modificato/aggiornato riportando l'evento in stato di <em><strong>&quot;bozza&quot;</strong></em> cliccando sul simbolo <span class="btn btn-icon btn-circle btn-sm btn-primary mr-3"><i class="fas fa-trash-restore"></i></span>
                    </p>

                    <h6 class="alert-heading" style="font-size: 1.0rem;">INVIO</h6>

                    <ol>
                        <li>Se l'evento è in stato <em><strong>&quot;confermato&quot;</strong></em> questo può essere inviato al Registro definitivamente cliccando sul simbolo <span class="btn btn-icon btn-circle btn-sm btn-info mr-3"><i class="fas fa-paper-plane"></i></span></li>
                    </ol>

                    <ul>
                        <li><strong>ATTIVITÀ DIDATTICA:</strong> può essere inviata direttamente sia dalle  ASD/SSD/ASD-APS che dal Comitato.</li>
                        <li><strong>ATTIVITÀ SPORTIVA E FORMATIVA:</strong> può essere inviata esclusivamente dal Comitato.</li>
                    </ul>
                </div>
            </b-alert>
        </b-card-text>

        <div class="row p-7">
            <div class="col-md-10">
                <div class="form-group">
                    <label for="idannuality" class="font-weight-bolder">Annualità:</label>
                    <select class="form-control" id="idannuality" v-model="idannuality">
                        <option v-for="annuality in annualities" :value="annuality.id">{{annuality.solarsannualitydisplay}}</option>
                    </select>
                </div>
            </div>

            <div class="col-md-2 text-right" v-if="['Superadmin', 'Segreteria Nazionale', 'Comitato'].includes($store.getters.currentUser.usertype)">
                <label class="font-weight-bolder">&nbsp;</label><br>
                <a v-if="typeof idannuality !== 'undefined'" :href="appendToken('/rseps/export-sportingclubs?idannuality=' + idannuality)" target="_blank" class="btn btn-info font-weight-bolder"><i class="fas fa-file-export"></i> Esporta Riepilogo Inserimento Attività</a>
            </div>
        </div>

        <b-tabs card justified v-model="tabIndex">
            <b-tab class="border border-primary border-top-0 rounded-bottom" title="Attività Sportiva" :title-link-class="linkClass(0)">
                <SmartTable ref="sportingevents" url="/rseps/sporting-event/index" :is_dialog="true"
                            base_url="/rseps/sporting-event" base_path="/rseps/sporting-event" :fixed_filters="[{ name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalSportingEvents">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Attività Sportiva</span></h2>
                    </template>

                    <template v-slot:title-actions="slotProps">
                        <div v-if="slotProps.values.top_actions.length" class="card-toolbar">
                            <a v-for="(action, index) in slotProps.values.top_actions" href="#" :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder mr-3'" @click.prevent="slotProps.values.doAction(action)">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{action.label}}
                            </a>

                            <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedEvents('sportingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Eventi Selezionati
                            </a>

                            <a href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="removeSelectedEvents('sportingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Eventi Selezionati
                            </a>
                        </div>
                    </template>

                    <template v-slot:td-rserrorstate="slotProps">
                        <td class="text-center">
                            <div v-if="typeof slotProps.values !== 'undefined'">
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'bozza'" href="#" title="Bozza" alt="Bozza" class="badge pill bg-primary text-white text-hover-white mr-2" style="font-size: 0.9em;"><i class="fas fa-hourglass-start text-white"></i> Bozza</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'confermato'" href="#" title="Confermato" alt="Confermato" class="badge pill bg-warning text-white text-hover-white mr-2" style="font-size: 0.9em;"><i class="fas fa-user-friends text-white"></i> Confermato</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'invio'" href="#" title="Invio" alt="Invio" class="badge pill bg-info text-white text-hover-white mr-2" style="font-size: 0.9em;"><i class="fas fa-paper-plane text-white"></i> Invio</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'inviato'" href="#" title="Inviato" alt="Inviato" class="badge pill bg-success text-white text-hover-white mr-2" style="font-size: 0.9em;" @click.prevent="tabIndex = 3"><i class="fas fa-check-circle text-white"></i> Inviato il {{slotProps.values.item.rssenddate}}</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'errore'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white mr-2" style="font-size: 0.9em;" @click.prevent="tabIndex = 3"><i class="fas fa-exclamation-circle text-white"></i></i> Errore</a>
                            </div>
                        </td>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                            <a v-if="slotProps.values.item.can_partecipate" href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Gestione Partecipanti" alt="Gestione Partecipanti" @click.prevent="$router.push('/rseps-subscription/event/' + slotProps.values.item.id)">
                                <i class="fas fa-sign-in-alt"></i>
                            </a>

                            <a v-if="slotProps.values.item.can_confirm" href="#" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Conferma" alt="Conferma" @click.prevent="confirmDraftEvent(slotProps.values.item.id)">
                                <i class="fas fa-check-circle"></i>
                            </a>

                            <a v-if="slotProps.values.item.can_restore" href="#" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Riporta in bozza" alt="Riporta in bozza" @click.prevent="restoreDraftEvent(slotProps.values.item.id)">
                                <i class="fas fa-trash-restore"></i>
                            </a>

                            <a v-if="slotProps.values.item.can_send" href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Invia" alt="Invia" @click.prevent="sendConfirmedEvent(slotProps.values.item)">
                                <i class="fas fa-paper-plane"></i>
                            </a>

                            <template v-for="(action, j) in slotProps.values.item.actions">
                                <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item['id'])">
                                    <i :class="action.icon"></i>
                                </a>

                                <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                                    <i :class="action.icon"></i>
                                </a>
                            </template>
                        </td>
                    </template>
                </SmartTable>
            </b-tab>

            <b-tab class="border border-primary border-top-0 rounded-bottom" title="Attività Formativa" :title-link-class="linkClass(1)">
                <SmartTable ref="trainingevents" url="/rseps/training-event/index" :is_dialog="true"
                            base_url="/rseps/training-event" base_path="/rseps/training-event" :fixed_filters="[{ name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalTrainingEvents">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Attività Formativa</span></h2>
                    </template>

                    <template v-slot:title-actions="slotProps">
                        <div v-if="slotProps.values.top_actions.length" class="card-toolbar">
                            <a v-for="(action, index) in slotProps.values.top_actions" href="#" :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder mr-3'" @click.prevent="slotProps.values.doAction(action)">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{action.label}}
                            </a>

                            <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedEvents('trainingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Eventi Selezionati
                            </a>

                            <a href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="removeSelectedEvents('trainingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Eventi Selezionati
                            </a>
                        </div>
                    </template>

                    <template v-slot:td-rserrorstate="slotProps">
                        <td class="text-center">
                            <div v-if="typeof slotProps.values !== 'undefined'">
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'bozza'" href="#" title="Bozza" alt="Bozza" class="badge pill bg-primary text-white text-hover-white mr-2" style="font-size: 0.9em;"><i class="fas fa-hourglass-start text-white"></i> Bozza</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'confermato'" href="#" title="Confermato" alt="Confermato" class="badge pill bg-warning text-white text-hover-white mr-2" style="font-size: 0.9em;"><i class="fas fa-user-friends text-white"></i> Confermato</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'invio'" href="#" title="Invio" alt="Invio" class="badge pill bg-info text-white text-hover-white mr-2" style="font-size: 0.9em;"><i class="fas fa-paper-plane text-white"></i> Invio</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'inviato'" href="#" title="Inviato" alt="Inviato" class="badge pill bg-success text-white text-hover-white mr-2" style="font-size: 0.9em;" @click.prevent="tabIndex = 3"><i class="fas fa-check-circle text-white"></i> Inviato il {{slotProps.values.item.rssenddate}}</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'errore'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white mr-2" style="font-size: 0.9em;" @click.prevent="tabIndex = 3"><i class="fas fa-exclamation-circle text-white"></i></i> Errore</a>
                            </div>
                        </td>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                            <a v-if="slotProps.values.item.can_partecipate" href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Gestione Partecipanti" alt="Gestione Partecipanti" @click.prevent="$router.push('/rseps-subscription/event/' + slotProps.values.item.id)">
                                <i class="fas fa-sign-in-alt"></i>
                            </a>

                            <a v-if="slotProps.values.item.can_confirm" href="#" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Conferma" alt="Conferma" @click.prevent="confirmDraftEvent(slotProps.values.item.id)">
                                <i class="fas fa-check-circle"></i>
                            </a>

                            <a v-if="slotProps.values.item.can_restore" href="#" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Riporta in bozza" alt="Riporta in bozza" @click.prevent="restoreDraftEvent(slotProps.values.item.id)">
                                <i class="fas fa-trash-restore"></i>
                            </a>

                            <a v-if="slotProps.values.item.can_send" href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Invia" alt="Invia" @click.prevent="sendConfirmedEvent(slotProps.values.item)">
                                <i class="fas fa-paper-plane"></i>
                            </a>

                            <template v-for="(action, j) in slotProps.values.item.actions">
                                <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item['id'])">
                                    <i :class="action.icon"></i>
                                </a>

                                <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                                    <i :class="action.icon"></i>
                                </a>
                            </template>
                        </td>
                    </template>
                </SmartTable>
            </b-tab>

            <b-tab class="border border-primary border-top-0 rounded-bottom" title="Attività Didattica" :title-link-class="linkClass(2)">
                <SmartTable ref="educationalevents" url="/rseps/educational-event/index" :is_dialog="true"
                            base_url="/rseps/educational-event" base_path="/rseps/educational-event" :fixed_filters="[{ name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalEducationalEvents">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Attività Didattica</span></h2>
                    </template>

                    <template v-slot:title-actions="slotProps">
                        <div v-if="slotProps.values.top_actions.length" class="card-toolbar">
                            <a v-for="(action, index) in slotProps.values.top_actions" href="#" :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder mr-3'" @click.prevent="slotProps.values.doAction(action)">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{action.label}}
                            </a>

                            <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedEvents('educationalevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Eventi Selezionati
                            </a>

                            <a href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="removeSelectedEvents('educationalevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Eventi Selezionati
                            </a>
                        </div>
                    </template>

                    <template v-slot:td-rserrorstate="slotProps">
                        <td class="text-center">
                            <div v-if="typeof slotProps.values !== 'undefined'">
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'bozza'" href="#" title="Bozza" alt="Bozza" class="badge pill bg-primary text-white text-hover-white mr-2" style="font-size: 0.9em;"><i class="fas fa-hourglass-start text-white"></i> Bozza</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'confermato'" href="#" title="Confermato" alt="Confermato" class="badge pill bg-warning text-white text-hover-white mr-2" style="font-size: 0.9em;"><i class="fas fa-user-friends text-white"></i> Confermato</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'invio'" href="#" title="Invio" alt="Invio" class="badge pill bg-info text-white text-hover-white mr-2" style="font-size: 0.9em;"><i class="fas fa-paper-plane text-white"></i> Invio</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'inviato'" href="#" title="Inviato" alt="Inviato" class="badge pill bg-success text-white text-hover-white mr-2" style="font-size: 0.9em;" @click.prevent="tabIndex = 3"><i class="fas fa-check-circle text-white"></i> Inviato il {{slotProps.values.item.rssenddate}}</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'errore'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white mr-2" style="font-size: 0.9em;" @click.prevent="tabIndex = 3"><i class="fas fa-exclamation-circle text-white"></i></i> Errore</a>
                            </div>
                        </td>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                            <a v-if="slotProps.values.item.can_partecipate" href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Gestione Partecipanti" alt="Gestione Partecipanti" @click.prevent="$router.push('/rseps-subscription/event/' + slotProps.values.item.id)">
                                <i class="fas fa-sign-in-alt"></i>
                            </a>

                            <a v-if="slotProps.values.item.can_confirm" href="#" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Conferma" alt="Conferma" @click.prevent="confirmDraftEvent(slotProps.values.item.id)">
                                <i class="fas fa-check-circle"></i>
                            </a>

                            <a v-if="slotProps.values.item.can_restore" href="#" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Riporta in bozza" alt="Riporta in bozza" @click.prevent="restoreDraftEvent(slotProps.values.item.id)">
                                <i class="fas fa-trash-restore"></i>
                            </a>

                            <a v-if="slotProps.values.item.can_send" href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Invia" alt="Invia" @click.prevent="sendConfirmedEvent(slotProps.values.item)">
                                <i class="fas fa-paper-plane"></i>
                            </a>

                            <template v-for="(action, j) in slotProps.values.item.actions">
                                <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item['id'])">
                                    <i :class="action.icon"></i>
                                </a>

                                <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                                    <i :class="action.icon"></i>
                                </a>
                            </template>
                        </td>
                    </template>
                </SmartTable>
            </b-tab>

            <b-tab class="border border-primary border-top-0 rounded-bottom" title="Monitoraggio" :title-link-class="linkClass(3)">
                <SmartTable ref="monitoraggio" url="/rsepsconimonitoring/index" :is_dialog="true"
                            base_url="/rsepsconimonitoring" base_path="/rsepsconimonitoring" :fixed_filters="[{ name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModal">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Monitoraggio Invii</span></h2>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td class="text-nowrap pr-0 text-center">
                            <a v-if="!slotProps.values.item.errormessage" href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-success mr-3" title="Dettagli" alt="Dettagli" @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                <i class="fas fa-eye"></i>
                            </a>

                            <a v-if="slotProps.values.item.errormessage" href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Dettagli" alt="Dettagli" @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                <i class="fas fa-exclamation-triangle"></i>
                            </a>
                        </td>
                    </template>
                </SmartTable>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import JwtService from "@/core/services/jwt.service";
 import ApiService from "@/core/services/api.service";
 import SmartTable from "@/view/components/SmartTable.vue";
 import Swal from 'sweetalert2';

 export default {
     data() {
         return {
             tabIndex: 0,

             alertdismissed: false,

             idannuality: '',
             annualities: [],
         };
     },

     components: {
         SmartTable,
     },

     created() {
         let tabIndex = localStorage.getItem('currentEpsTabIndex');
         if (tabIndex) {
             this.tabIndex = parseInt(tabIndex);
         }

         let idannuality = localStorage.getItem('currentEpsIdannuality');
         if (idannuality) {
             this.idannuality = parseInt(idannuality);
         }

         let url = '/rseps/sporting-event/load-options?type=annualities';

         ApiService.query(url)
                   .then((response) => {
                       this.annualities = response.data;

                       if (this.annualities.length) {
                           if (!this.idannuality)
                           {
                               this.idannuality = this.annualities[0].id;
                           }
                       }
                   })
                   .catch((error) => {
                       console.log(error);
                   })
     },

     watch: {
         tabIndex(newValue) {
             localStorage.setItem('currentEpsTabIndex', newValue);
         },

         $route() {
             let tabIndex = localStorage.getItem('currentEpsTabIndex');
             if (tabIndex) {
                 this.tabIndex = parseInt(tabIndex);
             }

             let idannuality = localStorage.getItem('currentEpsIdannuality');
             if (idannuality) {
                 this.idannuality = parseInt(idannuality);
             }
         },

         idannuality(newValue) {
             let vm = this;
             setTimeout(function() {
                 vm.$refs.sportingevents.refresh();
                 vm.$refs.trainingevents.refresh();
                 vm.$refs.educationalevents.refresh();
                 if (typeof vm.$refs.monitoraggio !== "undefined") vm.$refs.monitoraggio.refresh();
             }, 200);

             localStorage.setItem('currentEpsIdannuality', newValue);
         },
     },

     mounted() {
         this.$watch(
             () => {
                 let fields = this.$refs.sportingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.sportingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.sportingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.sportingevents.componentValuesloaded === null || this.$refs.sportingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.sportingevents.$refs.modalcomponent, 'idannuality');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;
                 }

                 if (doUpdate) {
                     let url = '/rseps/sporting-event/load-options?type=eventdates&idannuality=' + newValue;

                     ApiService.query(url)
                               .then((response) => {
                                   if (typeof response.data.dates !== 'undefined') {
                                       let dates = response.data.dates;

                                       this.setFieldAttribute(this.$refs.sportingevents.$refs.modalcomponent, 'eventstartdate', 'mindate', dates.mindate);
                                       this.setFieldAttribute(this.$refs.sportingevents.$refs.modalcomponent, 'eventstartdate', 'maxdate', dates.maxdate);

                                       this.setFieldAttribute(this.$refs.sportingevents.$refs.modalcomponent, 'eventenddate', 'mindate', dates.mindate);
                                       this.setFieldAttribute(this.$refs.sportingevents.$refs.modalcomponent, 'eventenddate', 'maxdate', dates.maxdate);
                                   }
                               })
                               .catch((error) => {
                                   console.log(error);
                               });
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.trainingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.trainingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.trainingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.trainingevents.componentValuesloaded === null || this.$refs.trainingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.trainingevents.$refs.modalcomponent, 'idannuality');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;
                 }

                 if (doUpdate) {
                     let url = '/rseps/training-event/load-options?type=eventdates&idannuality=' + newValue;

                     ApiService.query(url)
                               .then((response) => {
                                   if (typeof response.data.dates !== 'undefined') {
                                       let dates = response.data.dates;

                                       this.setFieldAttribute(this.$refs.trainingevents.$refs.modalcomponent, 'eventstartdate', 'mindate', dates.mindate);
                                       this.setFieldAttribute(this.$refs.trainingevents.$refs.modalcomponent, 'eventstartdate', 'maxdate', dates.maxdate);

                                       this.setFieldAttribute(this.$refs.trainingevents.$refs.modalcomponent, 'eventenddate', 'mindate', dates.mindate);
                                       this.setFieldAttribute(this.$refs.trainingevents.$refs.modalcomponent, 'eventenddate', 'maxdate', dates.maxdate);
                                   }
                               })
                               .catch((error) => {
                                   console.log(error);
                               });
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.educationalevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.educationalevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.educationalevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.educationalevents.componentValuesloaded === null || this.$refs.educationalevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.educationalevents.$refs.modalcomponent, 'idannuality');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;
                 }

                 if (doUpdate) {
                     let url = '/rseps/educational-event/load-options?type=eventdates&idannuality=' + newValue;

                     ApiService.query(url)
                               .then((response) => {
                                   if (typeof response.data.dates !== 'undefined') {
                                       let dates = response.data.dates;

                                       this.setFieldAttribute(this.$refs.educationalevents.$refs.modalcomponent, 'eventstartdate', 'mindate', dates.mindate);
                                       this.setFieldAttribute(this.$refs.educationalevents.$refs.modalcomponent, 'eventstartdate', 'maxdate', dates.maxdate);

                                       this.setFieldAttribute(this.$refs.educationalevents.$refs.modalcomponent, 'eventenddate', 'mindate', dates.mindate);
                                       this.setFieldAttribute(this.$refs.educationalevents.$refs.modalcomponent, 'eventenddate', 'maxdate', dates.maxdate);
                                   }
                               })
                               .catch((error) => {
                                   console.log(error);
                               });
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.sportingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.sportingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.sportingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.sportingevents.componentValuesloaded === null || this.$refs.sportingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.sportingevents.$refs.modalcomponent, 'idcommittee');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldSportingclub = null;
                 let fieldSportingclubValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldSportingclub = this.findField(this.$refs.sportingevents.$refs.modalcomponent, 'idsportingclub');
                         fieldSportingclubValue = JSON.parse(JSON.stringify(fieldSportingclub.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('sporting-event', this.$refs.sportingevents.$refs.modalcomponent, 'sportingclubs', 'idcommittee', value.id, 'idsportingclub',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                displayname: 'Seleziona un sodalizio',
                                            });
                                        },
                                        function(options) {
                                            fieldSportingclub = vm.findField(vm.$refs.sportingevents.$refs.modalcomponent, 'idsportingclub');

                                            if (fieldSportingclub) {
                                                vm.$set(fieldSportingclub, 'filter_options', options);

                                                if (typeof fieldSportingclub.value !== "undefined") {
                                                    if (fieldSportingclubValue !== null) {
                                                        fieldSportingclub.value = fieldSportingclubValue;
                                                    }
                                                    else {
                                                        fieldSportingclub.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.trainingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.trainingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.trainingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.trainingevents.componentValuesloaded === null || this.$refs.trainingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.trainingevents.$refs.modalcomponent, 'idcommittee');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldSportingclub = null;
                 let fieldSportingclubValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldSportingclub = this.findField(this.$refs.trainingevents.$refs.modalcomponent, 'idsportingclub');
                         fieldSportingclubValue = JSON.parse(JSON.stringify(fieldSportingclub.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('sporting-event', this.$refs.trainingevents.$refs.modalcomponent, 'sportingclubs', 'idcommittee', value.id, 'idsportingclub',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                displayname: 'Seleziona un sodalizio',
                                            });
                                        },
                                        function(options) {
                                            fieldSportingclub = vm.findField(vm.$refs.trainingevents.$refs.modalcomponent, 'idsportingclub');

                                            if (fieldSportingclub) {
                                                vm.$set(fieldSportingclub, 'filter_options', options);

                                                if (typeof fieldSportingclub.value !== "undefined") {
                                                    if (fieldSportingclubValue !== null) {
                                                        fieldSportingclub.value = fieldSportingclubValue;
                                                    }
                                                    else {
                                                        fieldSportingclub.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.educationalevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.educationalevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.educationalevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.educationalevents.componentValuesloaded === null || this.$refs.educationalevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.educationalevents.$refs.modalcomponent, 'idcommittee');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldSportingclub = null;
                 let fieldSportingclubValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldSportingclub = this.findField(this.$refs.educationalevents.$refs.modalcomponent, 'idsportingclub');
                         fieldSportingclubValue = JSON.parse(JSON.stringify(fieldSportingclub.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('sporting-event', this.$refs.educationalevents.$refs.modalcomponent, 'sportingclubs', 'idcommittee', value.id, 'idsportingclub',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                displayname: 'Seleziona un sodalizio',
                                            });
                                        },
                                        function(options) {
                                            fieldSportingclub = vm.findField(vm.$refs.educationalevents.$refs.modalcomponent, 'idsportingclub');

                                            if (fieldSportingclub) {
                                                vm.$set(fieldSportingclub, 'filter_options', options);

                                                if (typeof fieldSportingclub.value !== "undefined") {
                                                    if (fieldSportingclubValue !== null) {
                                                        fieldSportingclub.value = fieldSportingclubValue;
                                                    }
                                                    else {
                                                        fieldSportingclub.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.sportingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.sportingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.sportingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.sportingevents.componentValuesloaded === null || this.$refs.sportingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.sportingevents.$refs.modalcomponent, 'idregion');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldProvstate = null;
                 let fieldProvstateValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldProvstate = this.findField(this.$refs.sportingevents.$refs.modalcomponent, 'idprovstate');
                         fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('sporting-event', this.$refs.sportingevents.$refs.modalcomponent, 'provstates', 'idregion', value.id, 'idprovstate',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                provstatecode: 'Seleziona una provincia',
                                            });
                                        },
                                        function(options) {
                                            fieldProvstate = vm.findField(vm.$refs.sportingevents.$refs.modalcomponent, 'idprovstate');

                                            if (fieldProvstate) {
                                                vm.$set(fieldProvstate, 'filter_options', options);

                                                if (typeof fieldProvstate.value !== "undefined") {
                                                    if (fieldProvstateValue !== null) {
                                                        fieldProvstate.value = fieldProvstateValue;
                                                    }
                                                    else {
                                                        fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.sportingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.sportingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.sportingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.sportingevents.componentValuesloaded === null || this.$refs.sportingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.sportingevents.$refs.modalcomponent, 'idprovstate');
                     let value = this.getFieldValue(field);

                     if (typeof value !== "undefined" && value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldCity = null;
                 let fieldCityValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldCity = this.findField(this.$refs.sportingevents.$refs.modalcomponent, 'idcity');
                         fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('sporting-event', this.$refs.sportingevents.$refs.modalcomponent, 'cities', 'idprovstate', value.id, 'idcity',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                cityname: 'Seleziona una città',
                                            });
                                        },
                                        function (options) {
                                            fieldCity = vm.findField(vm.$refs.sportingevents.$refs.modalcomponent, 'idcity');

                                            if (fieldCity) {
                                                vm.$set(fieldCity, 'filter_options', options);

                                                if (typeof fieldCity.value !== "undefined") {
                                                    if (fieldCityValue !== null) {
                                                        fieldCity.value = fieldCityValue;
                                                    }
                                                    else {
                                                        fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.trainingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.trainingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.trainingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.trainingevents.componentValuesloaded === null || this.$refs.trainingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.trainingevents.$refs.modalcomponent, 'idregion');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldProvstate = null;
                 let fieldProvstateValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldProvstate = this.findField(this.$refs.trainingevents.$refs.modalcomponent, 'idprovstate');
                         fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('training-event', this.$refs.trainingevents.$refs.modalcomponent, 'provstates', 'idregion', value.id, 'idprovstate',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                provstatecode: 'Seleziona una provincia',
                                            });
                                        },
                                        function(options) {
                                            fieldProvstate = vm.findField(vm.$refs.trainingevents.$refs.modalcomponent, 'idprovstate');

                                            if (fieldProvstate) {
                                                vm.$set(fieldProvstate, 'filter_options', options);

                                                if (typeof fieldProvstate.value !== "undefined") {
                                                    if (fieldProvstateValue !== null) {
                                                        fieldProvstate.value = fieldProvstateValue;
                                                    }
                                                    else {
                                                        fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.trainingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.trainingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.trainingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.trainingevents.componentValuesloaded === null || this.$refs.trainingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.trainingevents.$refs.modalcomponent, 'idprovstate');
                     let value = this.getFieldValue(field);

                     if (typeof value !== "undefined" && value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldCity = null;
                 let fieldCityValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldCity = this.findField(this.$refs.trainingevents.$refs.modalcomponent, 'idcity');
                         fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('training-event', this.$refs.trainingevents.$refs.modalcomponent, 'cities', 'idprovstate', value.id, 'idcity',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                cityname: 'Seleziona una città',
                                            });
                                        },
                                        function (options) {
                                            fieldCity = vm.findField(vm.$refs.trainingevents.$refs.modalcomponent, 'idcity');

                                            if (fieldCity) {
                                                vm.$set(fieldCity, 'filter_options', options);

                                                if (typeof fieldCity.value !== "undefined") {
                                                    if (fieldCityValue !== null) {
                                                        fieldCity.value = fieldCityValue;
                                                    }
                                                    else {
                                                        fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.educationalevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.educationalevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.educationalevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.educationalevents.componentValuesloaded === null || this.$refs.educationalevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.educationalevents.$refs.modalcomponent, 'idregion');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldProvstate = null;
                 let fieldProvstateValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldProvstate = this.findField(this.$refs.educationalevents.$refs.modalcomponent, 'idprovstate');
                         fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('educational-event', this.$refs.educationalevents.$refs.modalcomponent, 'provstates', 'idregion', value.id, 'idprovstate',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                provstatecode: 'Seleziona una provincia',
                                            });
                                        },
                                        function(options) {
                                            fieldProvstate = vm.findField(vm.$refs.educationalevents.$refs.modalcomponent, 'idprovstate');

                                            if (fieldProvstate) {
                                                vm.$set(fieldProvstate, 'filter_options', options);

                                                if (typeof fieldProvstate.value !== "undefined") {
                                                    if (fieldProvstateValue !== null) {
                                                        fieldProvstate.value = fieldProvstateValue;
                                                    }
                                                    else {
                                                        fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.educationalevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.educationalevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.educationalevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.educationalevents.componentValuesloaded === null || this.$refs.educationalevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.educationalevents.$refs.modalcomponent, 'idprovstate');
                     let value = this.getFieldValue(field);

                     if (typeof value !== "undefined" && value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldCity = null;
                 let fieldCityValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldCity = this.findField(this.$refs.educationalevents.$refs.modalcomponent, 'idcity');
                         fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('educational-event', this.$refs.educationalevents.$refs.modalcomponent, 'cities', 'idprovstate', value.id, 'idcity',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                cityname: 'Seleziona una città',
                                            });
                                        },
                                        function (options) {
                                            fieldCity = vm.findField(vm.$refs.educationalevents.$refs.modalcomponent, 'idcity');

                                            if (fieldCity) {
                                                vm.$set(fieldCity, 'filter_options', options);

                                                if (typeof fieldCity.value !== "undefined") {
                                                    if (fieldCityValue !== null) {
                                                        fieldCity.value = fieldCityValue;
                                                    }
                                                    else {
                                                        fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Registro Sport e Salute", route: "/rseps-portal" },
         ]);
     },

     methods: {
         appendToken(url) {
             let href = process.env.VUE_APP_API_ENDPOINT + url;

             if (href) {
                 let parts = href.split('?');
                 if (parts.length >= 2) {
                     href += '&token=' + JwtService.getToken();
                 }
                 else
                 {
                     href += '?token=' + JwtService.getToken();
                 }
             }

             return href;
         },

         linkClass(idx) {
             if (this.tabIndex === idx) {
                 return ['text-primary', 'border-top-primary', 'border-left-primary', 'border-right-primary', 'text-center', 'font-size-h5'];
             } else {
                 return ['bg-primary', 'text-white', 'font-size-h5']
             }
         },

         closeModal() {
         },

         closeModalSportingEvents() {
             this.$refs.sportingevents.refresh();
         },

         closeModalTrainingEvents() {
             this.$refs.trainingevents.refresh();
         },

         closeModalEducationalEvents() {
             this.$refs.educationalevents.refresh();
         },

         findField(obj, name) {
             if (typeof obj !== "undefined" && obj && typeof obj.fields !== "undefined" && obj.fields) {
                 return obj.fields.find(item => item.name === name);
             }

             return null;
         },

         getFieldValue(field) {
             if (field) {
                 let value = typeof field.value !== "undefined" ? field.value : null;

                 if (value !== null) {
                     return (JSON.parse(JSON.stringify(value)));
                 }
             }

             return null;
         },

         setFieldVisible(obj, name, visible) {
             if (typeof obj.fields !== "undefined") {
                 let fieldIndex = obj.fields.findIndex(item => item.name === name);
                 if (fieldIndex) {
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_store', visible);
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_update', visible);
                 }
             }
         },

         setFieldAttribute(obj, name, attribute, value) {
             let fieldIndex = obj.fields.findIndex(item => item.name === name);
             if (fieldIndex) {
                 this.$set(obj.fields[fieldIndex], attribute, value);
             }
         },

         loadOptions(baseurl, obj, type, parameterName, parameterValue, fieldName, callbackEmptyOption, callbackOptions = null) {
             let url = '/rseps/' + baseurl + '/load-options?type=' + type + '&' + parameterName + '=' + parameterValue;

             ApiService.query(url)
                       .then((response) => {
                           let options = response.data;

                           if (typeof options === "undefined") options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           let options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                           }
                       })
         },

         getApiUrl() {
             return ApiService.getApiUrl();
         },

         confirmDraftEvent(idevent) {
             Swal.fire({
                 title: 'Confermare l\'evento selezionato?',
                 text: "Sei sicuro di voler confermare l\'evento selezionato?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/rsepsconimonitoring/confirm-draft-event';
                     let data = {
                         idevent: idevent,
                     };

                     ApiService.post(url, data)
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Evento confermato correttamente', {
                                           title: 'Evento confermato correttamente',
                                           variant: 'success',
                                           solid: true
                                       });

                                       this.$refs.sportingevents.refresh();
                                       this.$refs.trainingevents.refresh();
                                       this.$refs.educationalevents.refresh();
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si sono verificati degli errori nella conferma dell\'evento.', {
                                           title: 'Errore nella conferma dell\'evento',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }
                               })
                               .catch(({response}) => {
                                   console.log(response);
                               });

                     this.loading = false;
                 }
             });
         },

         restoreDraftEvent(idevent) {
             Swal.fire({
                 title: 'Riportare in bozza l\'evento selezionato?',
                 text: "Sei sicuro di voler riportare in bozza l\'evento selezionato?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/rsepsconimonitoring/restore-draft-event';
                     let data = {
                         idevent: idevent,
                     };

                     ApiService.post(url, data)
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Evento riportato in bozza correttamente', {
                                           title: 'Evento riportato in bozza correttamente',
                                           variant: 'success',
                                           solid: true
                                       });

                                       this.$refs.sportingevents.refresh();
                                       this.$refs.trainingevents.refresh();
                                       this.$refs.educationalevents.refresh();
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si sono verificati degli errori nel ripristino in bozza dell\'evento.', {
                                           title: 'Errore nel ripristino in bozza dell\'evento',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }
                               })
                               .catch(({response}) => {
                                   console.log(response);
                               });

                     this.loading = false;
                 }
             });
         },

         sendConfirmedEvent(event) {
             Swal.fire({
                 title: 'Inviare l\'evento selezionato?',
                 text: "Sei sicuro di voler inviare l\'evento selezionato?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 let can_send = false;
                 if (event.can_send && typeof event.numpartec !== 'undefined' && event.numpartec > 0) {
                    can_send = true;
                 }

                 if (result.isConfirmed && can_send) {
                     this.loading = true;

                     let url = '/rsepsconimonitoring/send-confirmed-event';
                     let data = {
                         idevent: event.id,
                     };

                     ApiService.post(url, data)
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Evento messo correttamente in coda per l\invio', {
                                           title: 'Evento messo correttamente in coda per l\'invio',
                                           variant: 'success',
                                           solid: true
                                       });

                                       this.$refs.sportingevents.refresh();
                                       this.$refs.trainingevents.refresh();
                                       this.$refs.educationalevents.refresh();
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si sono verificati degli errori nella messa in coda per l\'invio dell\'evento.', {
                                           title: 'Errore nella messa in coda per l\'invio dell\'evento',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }
                               })
                               .catch(({response}) => {
                                   console.log(response);
                               });

                     this.loading = false;
                 }

                 if (result.isConfirmed && !can_send) {
                    Swal.fire({
                        title: 'Nessun partecipante inserito per l\'evento',
                        text: "Per effettuare l\'invio inserire i partecipanti",
                        icon: 'warning',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok',
                    });
                 }
             });
         },

         sendSelectedEvents(table) {
             Swal.fire({
                 title: 'Conferma invio eventi selezionati',
                 text: "Sei sicuro di voler inviare gli eventi selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let events = [];
                     for (let i in this.$refs[table].items) {
                             if (this.$refs[table].items[i].can_send && typeof this.$refs[table].items[i].numpartec !== 'undefined' && this.$refs[table].items[i].numpartec > 0) {
                                if (this.$refs[table].items[i].checked) events.push({ idevent: this.$refs[table].items[i].id });
                             }
                     }

                    if (events.length) {
                         let url = '/rsepsconimonitoring/send-selected-events';
                         let data = {
                             events: events,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Eventi inviati correttamente', {
                                               title: 'Eventi inviati correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }

                                           this.$refs[table].refresh();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'invio degli eventi.', {
                                               title: 'Errore nell\'invio degli eventi',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.$refs[table].refresh();
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun evento selezionato',
                             text: "Per effettuare l\'invio selezionare almeno un evento valido",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         removeSelectedEvents(table) {
             Swal.fire({
                 title: 'Conferma rimozione eventi selezionati',
                 text: "Sei sicuro di voler rimuovere gli eventi selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let events = [];
                     for (let i in this.$refs[table].items) {
                         if (this.$refs[table].items[i].checked) events.push({ idevent: this.$refs[table].items[i].id } );
                     }

                     if (events.length) {
                         let url = '/rsepsconimonitoring/remove-selected-events';
                         let data = {
                             events: events,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Eventi rimossi correttamente', {
                                               title: 'Eventi rimossi correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }

                                           this.$refs[table].refresh();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nella rimozione degli eventi.', {
                                               title: 'Errore nella rimozione degli eventi',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.$refs[table].refresh();
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun evento selezionato',
                             text: "Per effettuare la rimozione selezionare almeno un evento",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },
     },
 };
</script>

<style>
 ul.nav-tabs .nav-item a.nav-link {
     height: 100%;
 }
</style>
